.MUICustomTable__btn {
    font-size: 1.5rem;
    height: 1rem !important;
    text-align: center;
    text-transform: none
}

.Schedule_time {
    display: flex;

}

.checkBoxStyle {
    display: flex;
    box-sizing: border-box;
    max-width: 100%;
    height: 24px;
    text-align: center;
    width: 24px;
    -webkit-box-align: center;
    align-items: center;
    min-width: 0px;
    min-height: 0px;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    outline: none;
    margin: 0px;
    border: 2px solid rgba(247, 247, 247, 0);
    border-image: initial;
    padding: 0px;
    border-radius: 4px;
    background-color: transparent;
}

.formItem_input {
    width: 759px;
    height: 32px;
}

.form_input {
    width: 399px;
    height: 32px;
}

.form__div_time {
    display: flex;
}

.form_div_subject {
    float: left;
}

.form_div_description {
    float: left;

}

.col_checkBox {
    text-align: center
}

.iconBox {
    margin: 22px 20px 10.5px;
    width: 64px;
    height: 64px;
    object-fit: contain
}

.loginUserName {
    font-family: 'Metric New';
    font-size: 16px;
    color: #000000;
}

.loginPassword {
    font-family: 'Metric New';
    font-size: 14px;
    color: rgba(0, 0, 0, 0.45)
}

.loginButton {
    font-family: 'Metric New';
    font-size: 14px;
    font-weight: bold;
    color: #000000
}

:root {
    --amplify-primary-color: #000000;
    --amplify-primary-tint: #000000;
    --amplify-primary-shade: #000000;
}

#report {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

@media(max-width:600px) {
    .contentWidth {
        width: calc(90vw);
    }

    .reportText {

        align-self: flex-end;
    }

    .searchTable {
        margin-left: 16px;
    }

}

@media(min-width:600px) {
    .contentWidth {
        margin-left: 30px;
    }

    .reportText {
        width: 100px;
        align-self: center;
    }

    .searchTable {
        width: 270px;
    }

}

a:link {
    color: #000000;
}

/* visited link */
a:visited {
    color: #000000;
}

/* mouse over link */
a:hover {
    color: #000000 !important;
}

/* selected link */
a:active {
    color: #000000;
}

.ql-toolbar.ql-snow {
    line-height: 24px !important;
}