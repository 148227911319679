#root {
  position: relative;
  min-height: 100vh;
}

#content-wrap {
  /* padding-bottom: 2.5rem; */
  /* Footer height */
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.logo {
  margin-left: 24px;
}

.navbar-brand {

  color: #FFFFFF;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: normal;
  --bs-navbar-brand-margin-end: 8px;
}

.map-container {
  height: 400px;
}

.sidebar {
  background-color: rgba(35, 55, 75, 0.9);
  color: #fff;
  padding: 6px 12px;
  font-family: monospace;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  margin: 12px;
  border-radius: 4px;
}

.marker {
  background-image: url('svg/marker.svg');
  background-size: cover;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  cursor: pointer;
}

.popup {
  width: 100px;
  height: 35px;
  border-radius: 50%;

}

.copyright {
  height: 19px;
  width: 199px;
  color: #FFFFFF;
  font-size: 16px;
  letter-spacing: 0.18px;
  line-height: 19px;
}

.FarmHeader {
  height: 21px;
  width: 71px;
  color: #000000;
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.11px;
  line-height: 21px;
}

.version {
  height: 21px;
  color: #656668;
  font-size: 14px;
  letter-spacing: 0.1px;
  line-height: 21px;
}

.service-status {
  height: 21px;
  width: 93px;
  color: #656668;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0.1px;
  line-height: 21px;
}

.data-from-last {
  height: 11px;
  width: 109px;
  color: #767676;
  font-family: Metric New;
  font-size: 10px;
  font-style: italic;
  letter-spacing: 0.11px;
  line-height: 11px;
}

#root {
  background-color: #F5F7F8;
}

#Navbar {
  padding: 0;
  height: 56px;
  background: linear-gradient(270deg, #088CB2 0%, #146693 8.78%, #23346A 36.9%, #222E61 74.96%, #19224A 100%);
}