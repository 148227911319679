.signOut {
  --amplify-primary-color: none;
}

@media(max-width:600px) {

  #top {
    display: flex;
    align-items: flex-start;
    white-space: nowrap;
  }

  #fullLogo {
    height: 22px;
  }

  .headbar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  #dashboard {
    display: flex;
    flex-direction: column;
  }

  #subdashborad {
    display: flex;
    flex-direction: row;
  }

  #title {
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: normal;
    font-weight: normal;
  }

  /* .labelStyle {
    height: 18px;
    width: 28px;
    color: #FFFFFF;
    font-size: 14px;
    letter-spacing: 0;
    line-height: normal;
  } */

  .codeStyle {
    color: #FFFFFF;
    font-family: 'MetricHPE';
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
  }

  #dashText {
    margin: 16px auto 0px 16px;
  }

  .reportWin {
    width: 100vw;
  }

  .reportText {
    display: flex;
    justify-content: center;
  }

  .reportLine {
    height: 40px;
  }

  .iconOuter {
    width: 40px;
    margin-left: 16px;
  }

  .iconBox {
    transform: scale(3);
  }

  .contentWidth {
    margin-left: 16px;
  }
}

@media(min-width:600px) {
  .reportWin {
    margin-top: 20px;
  }

  #subDashboard {
    margin-top: 14.5px;
  }

  #dashboardArea {
    margin: 31.5px auto 19px;
  }

  .codeStyle {
    font-size: 20px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.3px;
    color: #FFFFFF;
  }

  #title {
    font-size: 20px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.3px;
    color: #FFFFFF;
    /* margin-left: 8px; */
  }

  /* .labelStyle {
    font-size: 20px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.3px;
    color: #FFFFFF;
  } */

  /* #whiteLogo {
    display: none;
    align-content: center;
  } */

  .headbar {
    margin-left: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #dashboard {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  #subdashborad {
    display: flex;
    flex-direction: column;
  }

}